<template>
    <div style="width: 100%;" v-loading="loading">
        <div class="top" style="margin-left: 5px;">
            <span style="margin-left:28px;">
                <el-date-picker
                    v-model="value1"
                    size="small"
                    value-format="yyyy-MM-dd"
                    @change="checkdata"
                    type="daterange"
                    :picker-options="pickerOptions"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </span>
            <span style="margin-left:28px;">
                <el-select v-model="nation" placeholder="请选择国家" @change="checkdata">
                    <el-option :label="$t('buxian')" :value="null"></el-option>
                    <el-option v-for="item in nationlist" :key="item.values" :label="item.name" :value="item.values"></el-option>
                </el-select>
            </span>
        </div>
        <div style="width: 96%; margin-left:2%; height:97%; border: 1px solid #ddd; margin-top: 20px;">
            <p style="border-left: 4px solid blue; line-height: 20px; margin: 20px; padding-left: 10px;">数据统计</p>
            <el-table :data="list"
                :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}">
                <el-table-column prop="time" label="日期" :width="160"></el-table-column>
                <el-table-column prop="collectionTotalMoney" label="代收总金额"></el-table-column>
                <el-table-column prop="collectionTotalNum" label="代收总笔数"></el-table-column>
                <el-table-column prop="collectionService" label="代收服务费"></el-table-column>
                <el-table-column prop="payTotalMoney" label="代付总金额"></el-table-column>
                <el-table-column prop="payTotalNum" label="代付总笔数"></el-table-column>
                <el-table-column prop="payService" label="代付服务费"></el-table-column>
                <el-table-column prop="totalService" label="总服务费"></el-table-column>
            </el-table>
            <div class="page">
                <app-page :total="total" :current="pageNo" :pageSize="pageSize"
                    @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
            </div>
        </div>

        <div style="width: 96%; margin-left:2%; height:97%; border: 1px solid #ddd; margin-top: 20px;">
            <p style="border-left: 4px solid blue; line-height: 20px; margin: 20px; padding-left: 10px;">商户排行榜
                <!-- <span style="color:#f00;margin-left:15px;">注：数据每小时更新一次</span> -->
            </p>
            <el-tabs type="border-card">
                <el-tab-pane label="总服务费排行">
                    <div class="contbox">
                        <div class="contboxleft">
                            <el-table :data="list1"
                            :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}"
                            stripe style="width: 100%">
                                <el-table-column prop="chargeUser" align="center" width="120" label="排名"> 
                                    <template slot-scope="scope">
                                        <template v-if="pageNo==1">
                                            <span class="phspan span1" v-if="scope.$index == 0">{{scope.$index + 1}}</span>
                                            <span class="phspan span2" v-else-if="scope.$index == 1">{{scope.$index + 1}}</span>
                                            <span class="phspan span3" v-else-if="scope.$index == 2">{{scope.$index + 1}}</span>
                                            <span class="phspan spanother" v-else>{{scope.$index + 1}}</span>
                                        </template>
                                        <template v-if="pageNo>1">
                                            <span class="phspan spanother">{{(scope.$index+1) + (pageNo-1) * pageSize}}</span>
                                        </template>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="mchName" label="商户名"></el-table-column>
                                <el-table-column prop="mchId" label="商户id"></el-table-column>
                                <el-table-column prop="totalPayee" label="总收款"></el-table-column>
                                <el-table-column prop="totalPayment" label="总付款"></el-table-column>
                                <el-table-column prop="totalService" label="总服务费"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="日服务费排行">
                    <div class="contbox">
                        <div class="contboxleft">
                            <el-table :data="list2"
                            :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}"
                            stripe style="width: 100%">
                                <el-table-column prop="chargeUser" align="center" width="120" label="排名"> 
                                    <template slot-scope="scope">
                                        <template v-if="pageNo==1">
                                            <span class="phspan span1" v-if="scope.$index == 0">{{scope.$index + 1}}</span>
                                            <span class="phspan span2" v-else-if="scope.$index == 1">{{scope.$index + 1}}</span>
                                            <span class="phspan span3" v-else-if="scope.$index == 2">{{scope.$index + 1}}</span>
                                            <span class="phspan spanother" v-else>{{scope.$index + 1}}</span>
                                        </template>
                                        <template v-if="pageNo>1">
                                            <span class="phspan spanother">{{(scope.$index+1) + (pageNo-1) * pageSize}}</span>
                                        </template>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="mchName" label="商户名"></el-table-column>
                                <el-table-column prop="mchId" label="商户id"></el-table-column>
                                <el-table-column prop="totalPayee" label="总收款"></el-table-column>
                                <el-table-column prop="totalPayment" label="总付款"></el-table-column>
                                <el-table-column prop="totalService" label="总服务费"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import apiurl from "@/api/public"
import _api from "@/api/index"
export default {
    data(){
        return{
            loading:false,
            list: [],
            list1: [],
            list2: [],
            total: 1,
            pageNo: 1,
            pageSize: 10,
            selectDate: '',
            value1: null,
            nation: null,
            timeArr:null,
            pickerOptions: {
                onPick : ({maxDate, minDate}) => {
                    this.selectDate = minDate.getTime()
                    if (maxDate) {
                        this.selectDate = ''
                    }
                },
                disabledDate : (time) => {
                    if (this.selectDate !== '') {
                        const one = 24 * 24 * 3600 * 1000
                        const minTime = this.selectDate - one
                        const maxTime = this.selectDate + one
                        return time.getTime() < minTime || time.getTime() > maxTime
                    }
                }
            }
        }
    },
    created(){
        this.value1=this.getNowTime()
        this.getList()
        this.getList1()
        this.getList2()
        this.getnation()
    },
    methods:{
        getNowTime() {
            const start = new Date(new Date().getTime() - 3600 * 1000 * 24 * 15)
                .toISOString()
                .replace('T', ' ')
                .split('.')[0] //默认开始时间7天前
            const end = new Date(new Date().getTime() - 3600 * 1000 * 24)
                .toISOString()
                .replace('T', ' ')
                .split('.')[0]//默认结束时间1天前
            return [start, end]
        },
        getnation() {
            _api.get(apiurl.nationlist, {}).then(res => {
                let newarr = []
                for(let item of res.data){
                    newarr.push(item)
                }
                this.nationlist = newarr
            })
        },
        checkdata(){
            this.getList()
            this.getList1()
            this.getList2()
        },
        getList() {
            if (this.nation == null) {
                this.nation = "";
            }
            _api.get(apiurl.totalData, {
                page: this.pageNo,
                size: this.pageSize,
                time: this.value1,
                nation: this.nation,
            }).then(res => {
                this.loading = false
                this.list = res.data.data
                this.total = res.data.totalCount
            })
        },
        getList1() {
            if (this.nation == null) {
                this.nation = "";
            }
            _api.get(apiurl.ranking, {
                nation: this.nation
            }).then(res => {
                this.list1 = res.data
            })
        },
        getList2() {
            this.fn();
            if (this.nation == null) {
                this.nation = "";
            }
            _api.get(apiurl.ranking, {
                time: this.timeArr,
                nation: this.nation
            }).then(res => {
                this.list2 = res.data
            })
        },
        handchange(data) { //分页Size变化
            this.pageSize = data
            this.pageNo = 1
            this.getList()
        },
        currentchange(data) { //当前页变化
            this.pageNo = data
            this.getList()
        },
        fn(){
            var date = new Date();
            var UTCTime = Date.now() + date.getTimezoneOffset() * 60 * 1000;
            this.timeArr = UTCTime - 6 * 60 * 60 * 1000;
        },
    }

}
</script>
<style scoped>
    .ta {
        margin-top: 10px; 
        margin-bottom: 10px;                   
        line-height: 38px;
        width: 800px;
        text-align: left;
        width: 750px;
    }
    .div1 {
        width: 150px;
        height: 50px;
        line-height: 50px;
        background-color: aqua;
        border: 1px solid  black;
        text-align: center;
        font-size: 20px;
        border-radius: 5px;
        margin-left: 100px;
    }

    .phcont{ 
        padding:8px 0; 
        padding-right: 25px;
    }
    .phspan{ 
        display: inline-block; 
        color: #ffffff; 
        font-size: 12px; 
        padding:0 7px;  
        line-height: 20px; 
        height: 20px; 
        text-align: center; 
        border-radius: 10px;
    }

    .spanother{
        background: #b9b9b9;
    }
    .span1{
        background:#CD012C;
    }
    .span2{ 
        background:#BE4C05;
    }
    .span3{ 
        background:#093666;
    }

</style>